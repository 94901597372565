@import '../styles/colors.scss';

.Pricing {
    .pricelist {
        background-color: $brandYellow;
    }
    .card {
        background: #fff;
        border-radius: 20px;
        box-shadow: 0 6px 10px rgba(0,0,0,.1);
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;        
        .price-icon {
            width: 100%;
            height: auto;
            max-width: 60px;
            vertical-align: middle;
            margin-left: -10px;
            margin-right: 6px;
        }
        .btn {            
            margin-top: auto;
            display: block;
            text-align: center;
        }
        .price-point {            
            display: inline;
            font-size: clamp(2.3rem,6vw,3.95rem);
        }
    }
    .enterprise {
        background-color: #000;
        color: #fff;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 20px;
        display: flex;
        align-items: center;
        margin-top: 40px;
        .ent-icon {
            padding: 0 10px;
        }
        .enterprise-txt {
            flex-grow: 1;
            padding: 0 20px;
            p {
                margin-bottom: 0;
            }
        }
        h6 {
            span {
                color: $brandYellow;
            }
        }        
    }
}

@media (max-width: 860px) {
    .Pricing {
        .enterprise {
            flex-flow: column;
            align-items: flex-start;
            margin-top: 20px;
            .ent-icon {
                padding: 0;
            }
            .enterprise-txt {
                padding: 20px 0;
            }
        }
    }   
}
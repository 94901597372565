@import '../styles/colors.scss';

.hero {    
    min-height: 90vh;
    background: $brandYellow url('../assets/img/hero-bg.png') no-repeat;
    background-size: cover;
    background-position: left;
    display: flex;
    align-items: center;
    overflow: hidden;
    .black {
        margin-right: 20px;
    }
    .hero-image {
        text-align: center;
        margin-right: 40px;
        img {
            max-height: 60vh;
        }
    }
}

.short-desc {
    
}

.bg-wide {
    background: $brandYellow url('../assets/img/kneg-wide-bg.png') no-repeat;
    min-height: 25vh;
    background-size: cover;
    background-position: center;
}

.send-offer {
    background-color: $brandYellow;    
}

.overflow-mobile {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: -10%;
    width: 100%;
    height: 100%;    
    img {        
        margin-bottom: -5%;
        width: 90%;
    }    
}

@media (max-width: 860px) {    
    .hero {
        background-image: none;        
    }
    .hero-image {
        order: 2;
        img {
            margin-bottom: -80%;
            padding-top: 50px;
        }
    }
    .overflow-mobile {
        &.col-4 {
            grid-column: span 12;
        }        
        img {
            margin-top: 40px;
            width: 100%;
            max-width: 420px;
        }
    }    
}

@media (max-width: 560px) {
    .hero {
        .padding-l {
            padding-top: 100px;
        }
        .hero-image img {
            margin-bottom: -65%;
            padding-top: 20px;
        }
    }
}    
@import '../styles/colors.scss';

.CtaDownload {
    padding-bottom: 100px;
    .container {
        background-image: linear-gradient(to bottom right, #F2A91D, #FBDF00);    
        border-radius: 40px;
        box-shadow: 0 6px 20px rgba(0,0,0,.2);
    }
    .copy {
        padding: 60px;
        padding-right: 20px;
        box-sizing: border-box;
        .black {
            margin-right: 20px;
        }
    }
    a {
        img {
            max-width: 200px;
        }
    }
}

@media (max-width: 1024px) {
    .CtaDownload {
        .grid {
            .col-8 { grid-column: span 8; }
            .col-4 { grid-column: span 4; }
        }        
    }
}

@media (max-width: 860px) {
    .CtaDownload {        
        .grid {
            .col-8 { grid-column: span 12; }
            .col-4 { grid-column: span 12; }
        }
        text-align: center;        
        .container {
            overflow: hidden;
            border-radius: 15px; 
            .copy {
                padding: 40px 24px;                
            }           
        }        
        .overflow-mobile {
            padding: 0px;
            img {
                margin-bottom: -50%;
                width: 80%;
            }            
        }
    }    
}
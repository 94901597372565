@import '../styles/colors.scss';

.Features {
    .tasks {
        background-color: $brandYellow;
        z-index: 1;
        .task-phone {
            margin-top: -10%;
            margin-bottom: -10%;
        }
    }
    .offer {
        background-color: $bgGrey;
        img {
            max-height: 280px;
            margin: 20px auto;
            display: block;
        }
    }
    .easy {
        img {
            max-height: 140px;
            margin: 0px auto;
            margin-bottom: 60px;
            display: block;
        }
    }
}

@media (max-width: 1024px) {
    .Features {
        .tasks {
            overflow: hidden;
        }
        .task-phone {
            grid-column: span 12;
            text-align: center;
            img {
                max-width: 280px;
                padding-top: 40px;
            }
        }
    }
}

@media (max-width: 860px) {
    .Features {
        .h-align-resp {
            display: flex;
            img {
                
                max-width: 60px;
                height: min-content;
                margin: 0;
                margin-right: 20px;        
            }
            > div {
                margin-top: 15px;
            }
        }
        .easy {
            img {
                max-height: auto;                
            }
        }
    }
}

@media (max-width: 560px) {
    .Features {
        .tasks {
            overflow: initial;
        }
        .task-phone img {
            padding-top: 0;
        }
    }
}
@import '../styles/colors.scss';

.Contact {    
    .contact-image-frame {
        background-color: $bgGrey;
        position: relative;
        border-radius: 20px;
        width: 100%;
        padding: 20px;
        padding-bottom: 60%;    
        box-sizing: border-box;
        margin-bottom: 20px;    
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            max-width: 60%;
            max-height: 60%;
        }
    }
}
@import '../styles/colors.scss';

header {
    position: fixed;
    left: 0;
    right: 0;    
    top: 0;
    background: #fff;
    z-index: 2;
    box-shadow: 0 1px 10px rgba(0,0,0,.1);
    transition: all .2s ease-in-out;
    &.top {
      background: none;
      box-shadow: none;
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }
}

.logo {
    height: 36px;
    margin-top: 10px;    
    img {
        height: 100%;
    }
}

.main-nav {
  list-style: none;
  flex-grow: 2;
  text-align: right;
  padding: 0;
  margin: 0;  
  box-sizing: border-box;
  .btn {
    margin-right: 20px;
  }
  li {    
    display: inline-block;
    .nav-link {
      padding: 10px 20px;
      text-decoration: none;
      color: #333;
      font-weight: bold;
      position: relative;
      &:hover, &.active {
        color: #000;
        &:after {
            top: 80%;
            opacity: 1;
        }
      }
      &:after {
        pointer-events: none;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: $brandYellow;
        transition: all .2s ease-in-out;  
      }
    }
  }
}

.mobile-menu-toggle {
  display: none;
  width: 30px;
  height: 30px;  
  position: relative;  
  transition: all .2s ease-in-out;
  &:after, &:before, .menu-bar {
    content: "";
    position: absolute;    
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background: #333;    
    transition: all .2s ease-in-out; 
    transform-origin: center;
  }
  .menu-bar {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &:before {    
    top: 0%;
    width: 70%;    
  }
  &:after {
    bottom: 0%;    
  }
  &.active {
    .menu-bar {
      width: 0;      
    }
    &:before {
      width: 100%;      
      top: 44.5%;
      transform: rotate(45deg);
    }
    &:after {
      bottom: 44.5%;              
      transform: rotate(-45deg);
    }
  }
}

@media (max-width: 860px) {
  header {
    background: $brandYellow;
    box-shadow: none;
    &.top {
      background: $brandYellow;    
      box-shadow: none;
    }    
  }
  .main-nav {
    display: none;
  }

  .mobile-menu-toggle {
    display: block;
  }

  .main-nav {
    display: flex;
    background-color: $brandYellow;
    position: fixed;
    text-align: left;
    top: 66px;
    left: 100%;
    right: 0;
    width: 100%;
    bottom: 0;   
    flex-flow: column;
    padding: 20px;
    transition: all .2s ease-in-out;
    &.active {
      left: 0;
    }
    li {
      display: block;      
      .nav-link, .btn {
        padding: 20px;
        display: block;        
      }
      .nav-link {
        font-size: 20px;
      }
      .btn {
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
      }
    }
    span {
      margin-top: auto;
    }
  }
}
@import "../styles/colors.scss";

.footer {
    background: #000;
    color: rgba(255,255,255,.8);
    p {
        font-size: 14px;
    }
    a {
        color: #fff;
      }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;  
        box-sizing: border-box;
        .primary {
            margin-right: 20px;
        }  
        li {
            display: block;            
            a {
                display: inline-block;
                padding: 5px 10px;
                text-decoration: none;
                color: rgba(255,255,255,.9);
                font-weight: bold;
                position: relative;     
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}
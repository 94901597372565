@import "styles/colors.scss";

body {
    font-family: 'Poppins', sans-serif;    
    margin: 0;
    padding: 0;
    color: $fontColor;    
}

a {
  color: #000;
}

.arrow-link {
  text-decoration: none;
  font-weight: 500;
  transition: all .2s ease-in-out;
  &:after {
    content: "";
    position: relative;
    width: 26px;
    height: 26px;
    background: url('./assets/img/icon-forward.svg') no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-block;
    left: 0;
    vertical-align: bottom;
    transition: all .2s ease-in-out;
  }
  &:hover {
    &:after {
      left: 5px;
    }
  }
}

.btn {
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: bold;
  display: inline-block;
  text-decoration: none;
  color: #333;
  transition: all .2s ease-in-out;
  &.black {
    background: #000;
    color: $brandYellow;
    &:hover {
      color: #f6f413;
      box-shadow: 0 3px 10px rgba(0,0,0,.2);
    }
  }
  &.outline {
    box-shadow: inset 0 0 0 2px #333;
    &:hover {
      box-shadow: inset 0 0 0 2px #000, 0 3px 10px rgba(0,0,0,.2);
      color: #000;      
    }  
  }
  &.outline-white {
    box-shadow: inset 0 0 0 2px #e7e5e5;
    color: #e7e5e5;
    &:hover {
      box-shadow: inset 0 0 0 2px #fff, 0 3px 10px rgba(255,255,255,.2);
      color: #fff;
    }
  }
  &.primary {
    background: $brandYellow;
    &:hover {
      background: #f6f413;
      box-shadow: 0 8px 14px rgba(246,198,19,.4);
    }    
  }
  &.special {
    background: rgb(229,165,55);
    background: linear-gradient(45deg, rgba(191,139,50,1) 0%, rgba(255,234,92,1) 100%);
    background-size: 150%;
    background-position: right;
    &:hover {      
      box-shadow: 0 8px 14px rgba(246,198,19,.4);
      background-position: 0 20%;
    }
  }
}

p {
    font-size: clamp(12.46px,3.5vw,18.5px);
    line-height: 1.6;
    margin-bottom: 1.5rem;    
    margin-top: 0;
  }

  .center-text {
    text-align: center;
  }
  
  h1,h2,h3,h4,h5, h6 {      
      font-weight: bold;
      text-rendering: optimizelegibility;    
      margin-top: 0;    
  }
  
  h1 {
    font-size: clamp(2.3rem,6vw,3.95rem);
    line-height: 1.1;
    margin-bottom: 1.875rem;
  }
  
  h2 {
    font-size: clamp(2rem,6vw,2.75rem);
    line-height: 1.2;
    margin-bottom: 1.875rem;
  }
  
  h3 {
    font-size: clamp(1.55rem,5vw,2.3rem);
      line-height: 1.2;
      margin-bottom: 1.875rem;
  }
  
  h4 {
    font-size: clamp(1.4rem,4.55vw,1.875rem);
      line-height: 1.3;
      margin-bottom: 0.9rem;
  }
  
  h5 {
    font-size: clamp(1.25rem,4.3vw,1.5rem);
      line-height: 1.3;
      margin-bottom: 0.625rem;
  }

  h6 {
    font-size: clamp(1.15rem,4.3vw,1.3rem);
    font-weight: 500;
      line-height: 1.3;
      margin-bottom: 0.625rem;
  }


  .block-wrap {
    display: block;
    box-sizing: border-box;
    position: relative;
}

.container, .nav {
  max-width: 1200px;
  width: 86%;
  box-sizing: border-box;
  margin: 0 auto;
  display: block;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12,minmax(0,1fr));
  box-sizing: border-box; 
  &.gap-40 {
    grid-gap: 40px;
  }
  &.gap-20 {
    grid-gap: 20px;
  } 
  &.v-align {
    align-items: center;
  }  
}

.col {  
  box-sizing: border-box;
  width: 100%;
  position: relative;
  img {    
    max-width: 100%;
  }  
}

.col-12 { grid-column: span 12; }
.col-9 { grid-column: span 9; }
.col-8 { grid-column: span 8; }
.col-7 { grid-column: span 7; }
.col-6 { grid-column: span 6; }
.col-5 { grid-column: span 5; }
.col-4 { grid-column: span 4; }
.col-3 { grid-column: span 3; }
.col-2 { grid-column: span 2; }
.col-1 { grid-column: span 1; }

.padding-xl {
  padding-top: 160px;
  padding-bottom: 160px;
}

.padding-hero {
  padding-top: 180px;
  padding-bottom: 120px;
}

.padding-l {
  padding-top: 120px;
  padding-bottom: 120px;
}

.padding-m {
  padding-top: 80px;
  padding-bottom: 80px;
}

.padding-s {
  padding-top: 40px;
  padding-bottom: 40px;
}

@import "responsive.scss";